<template>
  <div>
    <!-- 横屏 -->
    <horizontalScreen :isFocus="fan">
      <!-- <scaleWrap> -->
      <div class="videoW">
        <!-- <input type="text" placeholder="3212"> -->
        <!-- <img src="https://static.ppzx168.com.cn/63135732a3acf.jpg" @click="videoPlay" class="poster" v-show="isPoster"
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: fill;
            z-index: 9;
          " /> -->
          <!-- <view v-show="isShow" @tap="videoPlay" class="video_info">横屏观看效果更佳 </view> -->
          <van-icon  class="playIcon" name="play-circle-o" @click="videoPlay"  v-show="isShow"/>
        <video
          id="myVideo"
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="allow"
          
          poster="https://static.ppzx168.com.cn/63135732a3acf.jpg"
          @timeupdate="videoUpdate"
          airplay="allow"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="portrait"
          :autoplay="true"
          class="tjm_video"
          src="https://static.ppzx168.com.cn/autumnvideo.mp4"
        ></video>

        <div
          class="edit"
          @click="focus('clear')"

          :focus="isFocus"
          :style="{ width: fan ? '290px' : '' }"
          v-show="isShowText"
        >
          <div
            class="editBox"
            v-show="isShowText"
            :style="{ width: fan ? '290px' : '' }"
          >
            <input
              id="input"
              ref="input"
              maxlength="15"
              :style="{
                fontSize: isFocus|| fan ? '17px' : '',
                textAlign: 'center',
                top: fan ? '17%' : '',
                width: '100%'
              }"
              type="text"
              @focus="focus"
              :autofocus="isFocus"
              @blur="blur"
              v-model="text"
            />
          </div>

          <div
            class="editTips"
            :style="{
              transform: fan ? 'unset' : '',
            }"
            v-show="!fan && !isFocus "
          >
            {{ editText }}
          </div>
        </div>

        <div class="saveVideo" :style="{top: fan ? '10%' : ''}" @click="saveVideo" v-show="text && isShowText">保存</div>
        <div class="chongVideo" v-show="isChong" @click="videoPlay">重看</div>
      </div>

      <!-- </scaleWrap> -->
    </horizontalScreen>
    <!-- 比例缩放容器 -->
    <!-- swiper -->
  </div>
</template>

<script>
import { Toast } from "vant";
import { setimg } from "../utils/api.js";
import horizontalScreen from "@/components/horizontal-screen";
import scaleWrap from "@/components/scale-wrap";
export default {
  components: {
    horizontalScreen,
    scaleWrap,
  },
  watch:{
    text(value){
      let ipt = document.getElementsByTagName('input')[0]
      let txtlen = value.length
      if (txtlen >= 10) {
        // ipt.style.fontSize = "10px"
      }
    }
  },
  data() {
    return {
      isPoster: true,
      text: "祝您：幸福美满，阖家团圆！",
      editText: "点击此处编辑,送出你的祝福",
      isShowText: false,
      isFocus: false,
      uni_video: null,
      fan: false,
      isShow:true,
      isChong:false
    };
  },
  mounted() {
    let that = this
    window.addEventListener(
      "onorientationchange" in window ? "orientationchange" : "resize",
      function () {
        if (window.orientation === 180 || window.orientation === 0) {
          //不用提示
          if (!this.fan) {
          if (
            window.orientation == null ||
            window.orientation === 180 ||
            window.orientation === 0
          ) {
            let timer = setInterval(() => {
              let toast = document.getElementsByClassName("van-toast")[0];
              console.log(toast);
              if (toast) {
                clearInterval(timer);
                toast.style.transform =
                  "translate3d(-50%,-50%,0) rotate(90deg)";
                console.log(toast);
              }else{
                clearInterval(timer);

              }
            }, 0);
          }
        }
        }
        if (window.orientation === 90 || window.orientation === -90) {
          that.fan = false;
          console.log(that.fan);
          if (!this.fan) {
         
            let timer = setInterval(() => {
              let toast = document.getElementsByClassName("van-toast")[0];
              console.log(toast);
              if (toast) {
                clearInterval(timer);
                toast.style.transform =
                  "translate3d(-50%,-50%,0)";
                console.log(toast);
              }else{
                clearInterval(timer);

              }
            }, 0);
        }
          // alert('横屏状态！');
        }
      },
      false
    );

    var elevideo = document.getElementById("myVideo");
    elevideo.addEventListener('play', function () { //结束
        console.log("播放开始");
        that.isShow = false
    }, false);
    elevideo.addEventListener('ended', function () { //结束
        console.log("播放结束");
        that.isChong = true
    }, false);
  },
  methods: {
    //聚焦
    focus(type) {
      this.isFocus = true;
      this.fan = true;
      this.$refs.input.focus()
      this.$emit("fan", this.fan);
    },
    blur() {
      this.isFocus = false;
      this.VerticallyOrHorizontally()
    },

    videoUpdate(e) {
      if (e.target.currentTime > 24) {
        this.isShowText = true;
      } else {
        this.isShowText = false;
      }
    },

    videoPlay() {
      var myVideo = document.getElementById("myVideo");
      console.log(myVideo);
      this.isPoster = false;
      this.isShow = false
      this.isChong = false
      this.fan = false
      myVideo.play();
    },

    saveVideo() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        alert("请在右上角...点击【在浏览器打开】");
      } else {
        Toast.loading({
          message: "合成中...",
          duration: 0,
          forbidClick: true,
        });
        if (!this.fan) {
          if (
            window.orientation == null ||
            window.orientation === 180 ||
            window.orientation === 0
          ) {
            let timer = setInterval(() => {
              let toast = document.getElementsByClassName("van-toast")[0];
              console.log(toast);
              if (toast) {
                clearInterval(timer);
                toast.style.transform =
                  "translate3d(-50%,-50%,0) rotate(90deg)";
                console.log(toast);
              }
            }, 0);
          }
        }

        setimg({
          name: this.chineseChar2englishChar(this.text),
        }).then((res) => {
          Toast.clear(); //隐藏loading
          if (res.code == 1) {
            Toast.loading({
              message: "保存中...",
              duration: 0,
              forbidClick: true,
            });

            this.clickDownLoad(res.data);
          } else {
            Toast.fail(res.msg);
          }
          console.log(res);
        });
      }
    },

    clickDownLoad(url) {
      console.log("下载");
      this.downLoad(url); //调用下载函数
      this.showVideo = false;
    },

    downLoad(url) {
      let that = this;

      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob"; // 返回类型blob

      xhr.addEventListener("load", function () {}); // 2
      // xhr.addEventListener('progress', onProgressHandler); // 6
      xhr.onload = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let blob = this.response;
          // 转换一个blob链接
          let u = window.URL.createObjectURL(new Blob([blob]));
          let a = document.createElement("a");
          a.download = "test.mp4"; //下载后保存的名称
          a.href = u;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
          // uni.hideLoading();
          that.fan = false;
        }
      };
      xhr.send();
          Toast.success("保存成功");

    },

    /**
     * 将中文符号转换成英文符号
     */
    chineseChar2englishChar(chineseChar) {
      // 将单引号‘’都转换成'，将双引号“”都转换成"
      let str = chineseChar.replace(/[’|‘]/g, "'").replace(/[“|”]/g, '"');
      // 将中括号【】转换成[]，将大括号｛｝转换成{}
      str = str
        .replace(/【/g, "[")
        .replace(/】/g, "]")
        .replace(/｛/g, "{")
        .replace(/｝/g, "}");
      // 将逗号，转换成,，将：转换成:
      str = str.replace(/，/g, ",").replace(/：/g, ":");
      //将《转换为<，将》转换为>
      str = str.replace(/《/g, "<").replace(/》/g, ">");
      //将句号。转换成.，将问号？转换为?
      str = str.replace(/。/g, ".").replace(/？/g, "?");
      //将！转换为!
      str = str.replace(/！/g, "!");
      //将￥转换为$
      str = str.replace(/￥/g, "$");
      return str;
    },

    VerticallyOrHorizontally(){
      let that = this
        if (window.orientation === 180 || window.orientation === 0) {
          //不用提示
        }
        if (window.orientation === 90 || window.orientation === -90) {
          that.fan = false;
          // alert('横屏状态！');
        }
    }
  },
};
</script>

<style scoped>
.videoW {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
}

.tjm_video {
  object-fit: fill;
  position: absolute;
  width: 100%;
  height: 100%;
}

.editBox {
  width: 250px;
  text-align: center;
  margin: 0 auto 2px;
}

input {
  background-color: transparent;
  border: none;
  color: #ff9966;
  font-size: 15px;
}

.edit {
  width: 250px;
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, 0px);
}

.editTips {
  margin: auto;
  width: 170px;
  z-index: 9;
  color: #ff9966;
  text-align: center;
  font-size: 12px;
  background: #f9fadb;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.saveVideo {
  position: absolute;
  top: 20%;
  right: 10%;
  padding: 3px;
  color: #fff;
  text-align: center;
  background: #ff9966;
  border-radius: 5px;
  font-size: 6px;
}
.chongVideo {
  position: absolute;
  top: 30%;
  right: 10%;
  padding: 3px;
  color: #fff;
  text-align: center;
  background: #ff9966;
  border-radius: 5px;
  font-size: 6px;
}

.playIcon{
  position: absolute;
    z-index: 9;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #fff;
}
</style>
