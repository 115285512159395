<template>
  <div>
    <section :style="horizontalCss" v-horizontal-screen>
      <!-- 页面具体内容 -->
      <slot></slot>
    </section>
  </div>
</template>
<script>
export default {
  props: {
    isFocus: Boolean,
  },
  watch: {
    isFocus(value) {
      console.log(value);
      if (value) {
        if (
          window.orientation == null ||
          window.orientation === 180 ||
          window.orientation === 0
        ) {
          let el = document.getElementsByTagName("section")[0];
          el.style.transform = `unset`;
          el.style.width = `100%`;
          el.style.height = `210px`;
          el.style.position = `fixed`;
          el.style.top = `10%`;
       
        }
      } else {
        if (
          window.orientation == null ||
          window.orientation === 180 ||
          window.orientation === 0
        ) {
          let width = document.documentElement.clientWidth,
            height = document.documentElement.clientHeight;
          let el = document.getElementsByTagName("section")[0];
          el.style.webkitTransform = el.style.transform = `rotate(90deg)`;
          el.style.width = `${height}px`;
          el.style.height = `${width}px`;
          el.style.webkitTransformOrigin = el.style.transformOrigin = `${
            width / 2
          }px center`;
          el.style.position = `unset`;
        }
      }
    },
  },
  data() {
    return {
      horizontalCss: {},
    };
  },
  directives: {
    "horizontal-screen": {
      bind(el, binding, vnode) {
        let self = vnode.context;

        function reset(init) {
          let width = document.documentElement.clientWidth,
            height = document.documentElement.clientHeight;
          //在竖屏状态我们通过添加transform:rotate(90deg)，来让这个页面横过来
          if (
            window.orientation == null ||
            window.orientation === 180 ||
            window.orientation === 0
          ) {
            //竖屏状态
            el.style.webkitTransform = el.style.transform = `rotate(90deg)`;
            el.style.width = `${height}px`;
            el.style.height = `${width}px`;
            el.style.webkitTransformOrigin = el.style.transformOrigin = `${
              width / 2
            }px center`;
            // setInterval(() => {
            //     let toast = document.getElementsByClassName('van-toast')[0]
            // console.log(toast);
            // toast.style.transform = 'translate3d(-50%,-50%,0)';
            // }, 0);

            //如果已经处于横屏状态就不做其他处理了
          } else if (window.orientation === 90 || window.orientation === -90) {
            //横屏状态
            el.style.webkitTransform = el.style.transform = `rotate(0)`;
            el.style.width = `${width}px`;
            el.style.height = `${height}px`;
          el.style.position = `unset`;

          }
        }
        reset(true);

        let timer = null;
        el.fn = function (e) {
          clearTimeout(timer);
          timer = setTimeout(reset, 300);
        };

        window.addEventListener("resize", el.fn, false);

        if ("onorientationchange" in window) {
          window.addEventListener("orientationchange", el.fn, false);
        }
      },
      unbind(el, binding, vnode) {
        window.removeEventListener("resize", el.fn, false);
        window.removeEventListener("orientationchange", el.fn, false);
      },
    },
  },
  mounted() {},
  methods: {
    // isFocus(e){
    //     console.log(e);
    // }
  },
};
</script>
