import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "https://api.ppzx168.com.cn/v1/user"

// 轮播图
export function setimg(params){
    return http.post(`${resquest}/img/setimg`,params)
}


