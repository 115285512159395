var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('horizontalScreen',{attrs:{"isFocus":_vm.fan}},[_c('div',{staticClass:"videoW"},[_c('van-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"playIcon",attrs:{"name":"play-circle-o"},on:{"click":_vm.videoPlay}}),_c('video',{staticClass:"tjm_video",attrs:{"id":"myVideo","playsinline":"true","webkit-playsinline":"true","x-webkit-airplay":"allow","poster":"https://static.ppzx168.com.cn/63135732a3acf.jpg","airplay":"allow","x5-video-player-type":"h5","x5-video-player-fullscreen":"true","x5-video-orientation":"portrait","autoplay":true,"src":"https://static.ppzx168.com.cn/autumnvideo.mp4"},on:{"timeupdate":_vm.videoUpdate}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowText),expression:"isShowText"}],staticClass:"edit",style:({ width: _vm.fan ? '290px' : '' }),attrs:{"focus":_vm.isFocus},on:{"click":function($event){return _vm.focus('clear')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowText),expression:"isShowText"}],staticClass:"editBox",style:({ width: _vm.fan ? '290px' : '' })},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],ref:"input",style:({
              fontSize: _vm.isFocus|| _vm.fan ? '17px' : '',
              textAlign: 'center',
              top: _vm.fan ? '17%' : '',
              width: '100%'
            }),attrs:{"id":"input","maxlength":"15","type":"text","autofocus":_vm.isFocus},domProps:{"value":(_vm.text)},on:{"focus":_vm.focus,"blur":_vm.blur,"input":function($event){if($event.target.composing){ return; }_vm.text=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fan && !_vm.isFocus ),expression:"!fan && !isFocus "}],staticClass:"editTips",style:({
            transform: _vm.fan ? 'unset' : '',
          })},[_vm._v(" "+_vm._s(_vm.editText)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.text && _vm.isShowText),expression:"text && isShowText"}],staticClass:"saveVideo",style:({top: _vm.fan ? '10%' : ''}),on:{"click":_vm.saveVideo}},[_vm._v("保存")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isChong),expression:"isChong"}],staticClass:"chongVideo",on:{"click":_vm.videoPlay}},[_vm._v("重看")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }